@font-face {
  font-family: "MulishRegular";
  src: local("MulishRegular"),
    url("./assets/fonts/Mulish-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "TiemposRegular";
  src: local("TiemposRegular"),
    url("./assets/fonts/TiemposHeadline-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Almarai";
  src: local("Almarai"),
    url("./assets/fonts/Almarai-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Almarai-bold";
  src: local("Almarai-bold"),
    url("./assets/fonts/Almarai-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "MulishRegular",
    "TiemposRegular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.header-logout {
  cursor: pointer;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.header-logout:hover {
  color: #3f3a64;
  fill: #3f3a64;
}

.modal-open {
  overflow: hidden;
}
html {
  /* margin-left: calc(100vw - 100%); */
}

html::-webkit-scrollbar {
  width: 10px;
}
html::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 10px;
}

.cs-message-list .cs-message.cs-message--incoming, .cs-message-list .cs-message-group.cs-message-group--incoming {
  margin-right: unset;
}
.cs-message-list .cs-message.cs-message--outgoing, .cs-message-list .cs-message-group.cs-message-group--outgoing {
  margin-left: unset;
}
.cs-message-list__scroll-wrapper>.cs-message, .cs-message-list__scroll-wrapper>.cs-message-group{
  max-width: 100%;
}
.cs-conversation-header__avatar {
  margin-left: 1rem;
}