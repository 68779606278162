.MuiTableSortLabel-icon {
    color:#fff !important
  }
  .MuiTableSortLabel-icon:hover p svg {
    color:#fff !important;
    opacity:1 !important
  }

.search-corrected div div input {
  border-radius: 30px;
  background-color: transparent;
  border: none;
  padding: 0 20px;
}