.course-item:hover div,
.course-item:hover div .item-meta-icon {
  color: #61589f !important;
}
.course-item.active,
.course-item.active div,
.course-item.active div .duration,
.course-item.active div .preview {
  background-color: #5d5488 !important;
  color: white !important;
  border-color: white !important;
}
.course-item.active,
.course-item.active div,
.course-item.active div .lesson-icon,
.course-item.active div .item-name,
.course-item.active div .duration,
.course-item.active div .preview,
.course-item.active div div p svg {
  color: white !important;
  border-color: white !important;
}
.side-hed {
  background-color: #3f3a64;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding-right: 50px;
  padding-left: 10px;
}
.side-hed input,
.side-hed input:focus {
  display: block;
  background-color: #3f3a64;
  color: #fff0d7;
  width: 60%;
  margin: 10px 0 10px 20px;
  border: none;
  outline: none;
  padding-left: 10px;
}
.side-hed input::placeholder {
  color: #fff0d7;
  padding-left: 4px;
}
.side-hed button {
  width: 10%;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff0d7;
}
.side-hed button i {
  color: #fff0d7;
}
.content-hed {
  display: flex;
  align-items: center;
  color: #fff0d7;
  font-weight: bold;
  font-size: 22px;
  padding-left: 70px;
}
.content-hed p:hover {
  color: #3f3a64;
  cursor: pointer;
}
.course-content-div {
  position: relative;
}
.controler {
  width: 35px;
  height: 35px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  position: absolute;
  top: 120px;
  cursor: pointer;
  font-size: 10px;
  color: #3f3a64;
  background-color: white;
}
.controler:hover {
  color: white;
  background-color: #3f3a64;
}
.cors-cont-cont {
  height: 100%;
  background-image: linear-gradient(to right, #dbdbdb, #fff 1%) !important;
}
.crs-cnt-cont .cors-cont-cont,
.crs-cnt-cont .content-hed {
  padding-left: 60px;
}
.crs-cnt-cont.addPadding .cors-cont-cont,
.crs-cnt-cont.addPadding .content-hed {
  transition: 0.3s;
  padding-left: 250px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.crs-cnt-cont.addPadding {
  transition: 0.3s;
  padding-left: 250px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .scrollable {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .muiltr-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  .scrollable {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .muiltr-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    width: 70%;
  }
}

/* For larger devices */
@media only screen and (min-width: 768px) {
  .scrollable {
    overflow: visible;
  }
}

.section-details-accordion::before {
  height: 0 !important;
}

#panel1a-header svg {
  font-size: x-large;
}
#panel2a-header svg {
  font-size: x-large;
}

.course-scrollable-side::-webkit-scrollbar {
  width: 10px;
}
.course-scrollable-side::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 10px;
}

.course-scrollable-side::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 10px;
}

.lessons-scrollable-side::-webkit-scrollbar {
  width: 5px;
}
.lessons-scrollable-side::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 10px;
}

.lessons-scrollable-side::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 10px;
}
