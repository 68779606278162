.categoryTag {
	border: 1px solid grey;
	border-radius: 20px;
	background-color: rgb(255, 255, 255);
	justify-content: space-between;
	align-items: center;
	display: flex;
	width: fit-content;
	padding: 3px 13px;
}

.categoryTag:hover{
    background-color: beige;
}

.active-free{
	color:blue;	
}

.non-active-free{
	color: gray;
}