.intro-div-frame{
  width: 100%;
  display:flex;
  justify-content: center;
}
.intro-div-frame iframe{
  width: 80%;
  height: 40rem;
}

@media (max-width: 768px) {
  /* Styles for screens smaller than 768px */
  .intro-div-frame iframe {
    height: 30rem;
  }
}

@media (max-width: 480px) {
  /* Styles for screens smaller than 480px */
  .intro-div-frame iframe {
    height: 15rem;
  }
}

.spinner-overlay {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
@keyframes spin {
  to {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
  }
}
