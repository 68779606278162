.header-social {
  border: 1px solid;
  padding: 6px;
  border-radius: 50%;
  background-color: #3f3a64;
  cursor: pointer;
  transition: all 0.2s linear;
}
.header-social svg {
  color: #fff;
  transition: all 0.2s linear;
}
