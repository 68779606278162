.colored .slick-slider .slick-dots {
  bottom: -50px;
  left: -100px;
}

.colored .slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  background: #fff;
}

.colored .slick-slider .slick-dots li button:before {
  color: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0px;
  left: 0;
  width: 15px;
  height: 15px;
  content: "•";
  text-align: center;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.colored .slick-slider .slick-list {
  height: 100%;
}
.colored .slick-slider .slick-list .slick-track {
  height: 100%;
}
.colored .slick-slider .slick-list .slick-track .slick-slide div {
  height: 100%;
}

.home-trainers .slick-slider .slick-dots li button:before {
  color: transparent;
  border: 2px solid #3f3a64;
  border-radius: 50%;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0px;
  left: 0;
  width: 15px;
  height: 15px;
  content: "•";
  text-align: center;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.home-trainers .slick-slider .slick-dots li.slick-active button:before {
  opacity: 1;
  background: #3f3a64;
}

.home-trainers .slick-slider .slick-dots {
  bottom: -25px;
}

.home-action {
  /* Set initial styles */
  display: flex;
  align-items: center;
  gap: 10px;
  color: #3f3a64;
  border: 1px solid;
  border-color: #3f3a64;
  padding: 10px 20px;
  border-radius: 50px;
  transition: background-color 0.3s, color 0.3s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  background-color: transparent;
}

.home-action:hover {
  /* Apply hover styles */
  background-color: #3f3a64;
  color: #fff;
}
.home-action.disabled {
  /* Apply disabled styles */
  opacity: 0.5;
  cursor: not-allowed;
}
.home-action.disabled:hover {
  /* Override hover styles for disabled button */
  background-color: initial;
  color: initial;
}

.home-action-danger {
  /* Set initial styles */
  display: flex;
  align-items: center;
  gap: 10px;
  color: #C10000;
  border: 1px solid;
  border-color: #C10000;
  padding: 10px 20px;
  border-radius: 50px;
  transition: background-color 0.3s, color 0.3s;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  background-color: transparent;
}

.home-action-danger:hover {
  /* Apply hover styles */
  background-color: #C10000;
  color: #fff;
}

.hero-category {
  transition: color 0.3s;
  transition-timing-function: ease-in-out;
}

.hero-category:hover {
  color: #3f3a64;
}
.colored-top {
  transition: all 0.3s;
  transition-timing-function: ease-in-out;
}
.colored-top:hover {
  transform: translateY(-20px);
}

/* Extra small devices (phones, 600px and below) */
@media only screen and (max-width: 600px) {
  .colored .slick-slider {
    min-height: 45%;
    max-height: 70%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and above) */
@media only screen and (min-width: 600px) {
  .colored .slick-slider {
    min-height: 50%;
    max-height: 70%;
  }
}

/* Medium devices (landscape tablets, 768px and above) */
@media only screen and (min-width: 768px) {
  .colored .slick-slider {
    min-height: 50%;
    max-height: 70%;
  }
}

/* Large devices (laptops and desktops, 992px and above) */
@media only screen and (min-width: 992px) {
  .colored .slick-slider {
    min-height: 50%;
    max-height: 70%;
  }
  .colored .slick-slider .slick-dots {
    bottom: -50px;
    left: -100px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and above) */
@media only screen and (min-width: 1200px) {
  .colored .slick-slider {
    min-height: 60%;
    max-height: 70%;
  }
  .colored .slick-slider .slick-dots {
    bottom: -50px;
    left: -65px;
  }
}

@media only screen and (min-width: 1900px) {
  .colored .slick-slider {
    min-height: 55%;
    max-height: 70%;
  }
  .colored .slick-slider .slick-dots {
    bottom: -50px;
    left: -100px;
  }
}
